<template>
  <div>
    <el-dialog :title="text + '章节'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="90px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="章节名称"
                          prop="chapter_desc">
              <el-input placeholder="请输入"
                        v-model="form.chapter_desc"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="科目"
                          prop="subject_id">
              <el-select style="width:100%"
                         v-model="form.subject_id"
                         :disabled="form.chapter_parent_id.length?true:false"
                         clearable
                         filterable
                         @change="sbChange"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="教材"
                          prop="textbook_id">
              <el-select style="width:100%"
                         v-model="form.textbook_id"
                         :disabled="form.chapter_parent_id.length?true:false"
                         clearable
                         filterable
                         @change="tbChange"
                         placeholder="请选择">
                <el-option v-for="item in bookList"
                           :key="item.textbook_id"
                           :label="item.textbook_name"
                           :value="item.textbook_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="年级"
                          prop="grade_id">
              <el-select style="width:100%"
                         v-model="form.grade_id"
                         :disabled="form.chapter_parent_id.length?true:false"
                         clearable
                         @change="gradeChange"
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="父级章节">
              <el-cascader v-model="form.chapter_parent_id"
                           :options="chapterList"
                           style="width:100%"
                           :show-all-levels="false"
                           @change="chaChange"
                           :props="{ checkStrictly: true,value:'chapter_id',
                           label:'chapter_desc',children:'child'}"
                           clearable></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="序号">
              <el-input placeholder="请输入"
                        v-model="form.serial_no"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="出现频率">
              <el-input placeholder="请输入"
                        v-model="form.chapter_frequency"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="星级"
                          prop="chapter_star">
              <el-input-number placeholder="请输入"
                               controls-position="right"
                               :step="1"
                               step-strictly
                               :min="1"
                               :max="5"
                               v-model="form.chapter_star"
                               style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="易错指数"
                          prop="error_prone">
              <el-input-number placeholder="请输入"
                               controls-position="right"
                               :step="1"
                               step-strictly
                               type="number"
                               :min="1"
                               :max="5"
                               v-model="form.error_prone"
                               style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      level: 1,
      rules: {
        chapter_desc: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        textbook_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        error_prone: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        chapter_star: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
      subjectList: [],
      bookList: [],
      gradeList: [],
      text: '',
      chapterList: [],
      copyList: {}
    }
  },

  methods: {
    handleClose () {
      this.level = 1
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))

        form.chapter_parent_id = form.chapter_parent_id.length ? form.chapter_parent_id[form.chapter_parent_id.length - 1] : 0
        this.$http({
          url: '/api/v2/chapter/add',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },
    getList (form) {
      this.getSubjectList()
      this.gteGradeList()
      // this.getBookList()
      this.getChapterList(form)
    },
    getChapterList (form) {
      this.$http({
        url: '/api/v2/chapter/lst',
        method: 'get',
        params: {
          page: 1,
          limit: 10000,
          book_id:form.textbook_id,
          grade_id:form.grade_id,
          subject_id:form.subject_id
        }
      }).then(res => {
        this.chapterList = res.data.list
        let copyList = this.flatList(this.chapterList)
        copyList.forEach(item => {
          this.copyList[item.chapter_id] = item
        });
        if (form) {
          this.setForm(form)
        }
      })
    },
    getSubjectList () {
      this.$http({
        url: '/api/v2/chapter/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
      })
    },
    gteGradeList () {
      this.$http({
        url: '/api/v2/public/grade_lst',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    getBookList () {
      this.$http({
        url: '/api/v2/textbook/lst',
        method: 'get',
        params: {
          page: 1,
          limit: 10000,
          subject_id: this.form.subject_id,
        }
      }).then(res => {
        this.bookList = res.data.list
      })
    },
    flatList (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        if (list[i].subject_id == 7 || list[i].subject_id == 9) {
          if (list[i].level == 4) {
            list[i].disabled = true
          }
        } else {
          if (list[i].level == 3) {
            list[i].disabled = true
          }
        }
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.flatList(list[i].child))
        }
      }
      return allList
    },
    tbChange () {
      this.getChapterList(this.form)

    },
    gradeChange () {
      this.getChapterList(this.form)
    },
    chaChange () {
      if (!this.form.chapter_parent_id.length) return
      let row = this.copyList[this.form.chapter_parent_id[this.form.chapter_parent_id.length - 1]]
      if (row.subject_id) {
        this.form.subject_id = row.subject_id
        this.form.grade_id = row.grade_id
        this.form.textbook_id = row.textbook_id
      }
    },
    // 科目改变触发回调
    sbChange () {
      this.getBookList()
      this.getChapterList(this.form)
      if (!this.form.chapter_parent_id.length) return
      this.chaChange()
    },
    setForm (row) {
      var form = {
        chapter_parent_id: this.searchParent(row.chapter_id),
        serial_no: row.serial_no,
        chapter_desc: row.chapter_desc,
        subject_id: row.subject_id,
        chapter_frequency: row.chapter_frequency,
        chapter_star: row.chapter_star,
        textbook_id: row.textbook_id,
        grade_id: row.grade_id,
        chapter_id: row.chapter_id
      }
      this.form = form
    },
    searchParent (id) {
      var arr = []
      var row = this.copyList[id]
      if (row.chapter_parent_id) {
        row = this.copyList[row.chapter_parent_id]
        arr.unshift(row.chapter_id)
        if (row.chapter_parent_id) {
          row = this.copyList[row.chapter_parent_id]
          arr.unshift(row.chapter_id)
          if (row.chapter_parent_id) {
            row = this.copyList[row.chapter_parent_id]
            arr.unshift(row.chapter_id)
          }
        }
      }
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
::v-deep .el-input-number.is-controls-right .el-input__inner {
  text-align: left;
}
</style>